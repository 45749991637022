<template>
    <form @submit.prevent="filterTable">
        <div class="row">
            <div class="col-12 col-md-6">
                <label class="akkurate-dark small d-none d-lg-block" for="search_term">Search Term</label>
                <b-form-group id="search_term" label-for="search_term">
                    <b-form-input class="mt-2 field-container fields" type="text" v-model="form.search_term"
                        placeholder="Search by firstname, lastname, phone number, email">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col mb-2 mb-md-0">
                <label class="akkurate-dark small d-none d-lg-block" for="type">Status</label>
                <multiselect class="mt-2 field-container" v-model="form.status" :options="status_option"></multiselect>
            </div>
            <div class="d-grid gap-2 col-md-2 d-flex align-content-center justify-content-center mt-auto ">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100 d-flex align-items-center justify-content-center"><i
                        class="fas fa-search "></i><span class="d-none d-lg-block ms-2">Search</span></b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>

        </div>
        <div class="row mt-2" v-if="more_details">
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="from_date">From Date</label>
                <b-form-group id="from_date" label-for="from_date">
                    <b-form-input class="mt-2 field-container fields" type="date"
                        v-model="form.start_date"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="to_date">To Date</label>
                <b-form-group id="to_date" label-for="to_date">
                    <b-form-input class="mt-2 field-container fields" type="date"
                        v-model="form.end_date"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <multiselect class="mt-2 field-container" v-model="form.branch" :options="form.branch_option"
                    track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                </multiselect>
            </div>
            <div class="col-12 col-md-3" v-show="more_details">
                <label class="akkurate-dark small" for="trans_source">Source</label>
                <multiselect class="field-container mt-2" v-model="form.trans_source" :options="trans_source_options"
                    placeholder="Transaction Source" label="name" selectLabel="" deselectLabel="">
                </multiselect>
            </div>
        </div>

    </form>

</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['form', 'filterTable'],
    data() {
        return {
            status_option: ['Pending', 'Active', 'Inactive', "Dormant", "Closed"],
            more_details: false,
            trans_source_options: [
                { name: 'USSD', value: "ussd" },
                { name: 'WEB', value: "web" },
                { name: 'MOBILE BANKER', value: "mobile_banker" },
                // { name: 'Deposit', value: "deposit" },
                // { name: 'Withdrawal', value: "withdrawal" },
            ],
        }
    },

    methods: {
        show_more() {
            this.more_details = !this.more_details
        },

    }
}

</script>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
